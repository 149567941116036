import React from 'react'
import { captureException } from '@sentry/core'
import { toaster } from '@/components/Toaster/toaster'
import { t } from 'i18next'
import type { StatusResponse } from '../api-client/generated'
import { isStatusResponseError } from '@/utils/api'

const useErrorEffect = <Response, T extends Error = Error>(
  response?: Response | StatusResponse,
  error?: T,
) => {
  const isStatusError = isStatusResponseError(response)

  const showError = React.useCallback((message?: string) => {
    toaster.error({
      title: t('errors.toastTitle'),
      message: message || 'Something went wrong',
    })
  }, [])

  React.useEffect(() => {
    if (isStatusError) {
      captureException(response)
      console.error(`${response.status}: ${response.error}`)
      showError(response.error)
    }

    if (error) {
      captureException(error)
      console.error(
        `${error.name}: ${error.message}${`\ncause: ${error.cause}`}\n${error.stack}`,
      )
      showError(error.message)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, isStatusError])
}

export default useErrorEffect
