import Link from 'next/link'
import { cx } from 'class-variance-authority'
import { usePathname } from 'next/navigation'
import { navItems } from '@/constants/navigation'

import styles from './Navigation.module.scss'

export default function Navigation() {
  const currentRoute = usePathname()

  return (
    <nav className={styles.nav} aria-label="Navigation panel">
      <div className={styles.nav_wrapper}>
        {navItems.map((item) => (
          <Link
            key={item.url}
            href={item.url}
            className={cx([
              styles.link,
              { [styles.active]: currentRoute === item.url },
            ])}
          >
            {item.title}
          </Link>
        ))}
      </div>
    </nav>
  )
}
