import { VariantProps, cva } from 'class-variance-authority'
import React from 'react'

import { Button } from '../Button'
import { Icon } from '../Icon'
import { ModalOverlay } from '../ModalOverlay'

import styles from './Dialog.module.scss'

const contentStyle = cva(styles.content, {
  variants: {
    size: {
      sm: styles.content_sizeSm,
      md: styles.content_sizeMd,
      lg: styles.content_sizeLg,
    },
    isAnimated: {
      true: styles.content_animated,
      false: '',
    },
    color: {
      white: styles.content_colorWhite,
      gray: styles.content_colorGray,
    },
  },
  defaultVariants: {
    size: 'sm',
    isAnimated: true,
    color: 'gray',
  },
})

type ContentStyleProps = VariantProps<typeof contentStyle> & {
  asPortal?: boolean
  title?: string
  description?: string
  withoutCloseButton?: boolean
  onCloseModal: () => void
}

type DialogContentProps = React.ComponentPropsWithoutRef<'div'> &
  ContentStyleProps

/**
 * The `Dialog` component is part of a dialog system, used to create modal dialogs.
 * This component handles the dialog content and can be styled according to the provided props.
 *
 * @component
 *
 * @prop {React.ReactNode} children - The content to be displayed within the dialog.
 * @prop {string} title - An optional title for the dialog.
 * @prop {string} [description] - An optional description for the dialog.
 * @prop {'sm' | 'md' | 'lg'} [size='sm'] - Size variant of the dialog content.
 * @prop {'white' | 'gray'} [color='gray'] -  Color variant of the dialog container.
 *
 */

const Dialog = React.forwardRef<HTMLDivElement, DialogContentProps>(
  (restProps, ref) => {
    const {
      withoutCloseButton,
      description,
      title,
      className,
      children,
      color,
      size,
      onCloseModal,
      ...props
    } = restProps

    return (
      <ModalOverlay onCloseModal={onCloseModal}>
        <div
          {...props}
          className={contentStyle({ size, color, className })}
          ref={ref}
        >
          {!withoutCloseButton && (
            <Button
              size="iconSm"
              type="button"
              color="primary"
              aria-label="dialog-close"
              onClick={onCloseModal}
              className={styles.close}
            >
              <Icon
                name="close"
                width={24}
                height={24}
                className={styles.icon}
              />
            </Button>
          )}

          {title && <h3 className={styles.content__heading}>{title}</h3>}
          {description && (
            <p className={styles.content__description}>{description}</p>
          )}
          {children}
        </div>
      </ModalOverlay>
    )
  },
)

Dialog.displayName = 'Dialog'

export default React.memo(Dialog)
