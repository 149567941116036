import { toast as toastify } from 'react-toastify'

import Toast, { type ToastProps } from './Toast'
import { ToastIcon } from './ToastIcon'

import styles from './toaster.module.scss'

/** use toaster.success({title:'Oops' message:'404 not found'}) */

export const toaster = {
  success: (content: ToastProps) =>
    toastify.success(<Toast {...content} />, {
      icon: <ToastIcon variant="success" />,
      bodyClassName: styles.toast_body,
    }),

  error: (content: ToastProps) =>
    toastify.error(<Toast {...content} />, {
      icon: <ToastIcon variant="error" />,
      bodyClassName: styles.toast_body,
    }),
  warning: (content: ToastProps) =>
    toastify.warning(<Toast {...content} />, {
      icon: <ToastIcon variant="warning" />,
      bodyClassName: styles.toast_body,
    }),
}
