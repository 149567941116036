import { type VariantProps, cva } from 'class-variance-authority'
import React from 'react'

import { Icon } from '../Icon'

import styles from './Button.module.scss'

const buttonStyle = cva(styles.button, {
  variants: {
    variant: {
      primary: styles.button,
      link: styles.button_variantLink,
    },
    color: {
      primary: styles.button_colorPrimary,
      black: styles.button_colorBlack,
      white: styles.button_colorWhite,
      grey: styles.button_colorGrey,
      transparent: styles.button_colorTransparent,
    },
    size: {
      lg: styles.button_sizeLg,
      m: styles.button_sizeM,
      icon: styles.button_sizeIcon,
      iconSm: styles.button_sizeIconSm,
    },
  },
  defaultVariants: { variant: 'primary', color: 'primary', size: 'lg' },
})

type ButtonStyleProps = VariantProps<typeof buttonStyle> & {
  disabled?: boolean
  loading?: boolean
}

type ButtonProps = React.ComponentPropsWithoutRef<'button'> & ButtonStyleProps

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (restProps, ref) => {
    const {
      className,
      children,
      color,
      variant,
      size,
      loading,
      disabled,
      ...props
    } = restProps

    return (
      // eslint-disable-next-line react/button-has-type
      <button
        ref={ref}
        className={buttonStyle({ color, size, variant, className })}
        disabled={disabled || loading}
        {...props}
      >
        {loading ? (
          <Icon name="dotsAnimated" width={24} height={24} />
        ) : (
          children
        )}
      </button>
    )
  },
)

Button.displayName = 'Button'

export default React.memo(Button)
