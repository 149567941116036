/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GeneralError } from '../models/GeneralError'
import type { ReportInfo } from '../models/ReportInfo'
import type { State } from '../models/State'
import type { StatusResponse } from '../models/StatusResponse'
import type { SubscriptionPlan } from '../models/SubscriptionPlan'

import type { CancelablePromise } from '../core/CancelablePromise'
import { OpenAPI } from '../core/OpenAPI'
import { request as __request } from '../core/request'

export class LandingService {
  /**
   * @returns ReportInfo list of user reports
   * @returns GeneralError response for error
   * @throws ApiError
   */
  public static getExamples(): CancelablePromise<
    Array<ReportInfo> | GeneralError
  > {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/examples',
    })
  }

  /**
   * @returns SubscriptionPlan list of available subscription plans
   * @returns GeneralError response for error
   * @throws ApiError
   */
  public static getPlans(): CancelablePromise<
    Array<SubscriptionPlan> | GeneralError
  > {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/plans',
    })
  }

  /**
   * @returns State list of USA states
   * @returns GeneralError response for error
   * @throws ApiError
   */
  public static getStates(): CancelablePromise<Array<State> | GeneralError> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/states',
    })
  }

  /**
   * @returns any cookies acception response
   * @returns GeneralError response for error
   * @throws ApiError
   */
  public static getCookies(): CancelablePromise<
    | {
        sended: boolean
        accepted?: boolean
      }
    | GeneralError
  > {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/cookies',
    })
  }

  /**
   * @param requestBody
   * @returns StatusResponse operation status response
   * @returns GeneralError response for error
   * @throws ApiError
   */
  public static postCookies(
    requestBody?: boolean,
  ): CancelablePromise<StatusResponse | GeneralError> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/cookies',
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * @param formData opt out form body
   * @returns StatusResponse operation status response
   * @returns GeneralError response for error
   * @throws ApiError
   */
  public static postOptout(formData?: {
    fullname: string
    state: string
    photo: Blob
    tiktok: string
    instagram: string
    facebook: string
    twitter: string
    request: string
  }): CancelablePromise<StatusResponse | GeneralError> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/optout',
      formData: formData,
      mediaType: 'multipart/form-data',
    })
  }
}
