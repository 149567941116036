import React from 'react'
import type { AppProps } from 'next/app'
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import { Slide, ToastContainer } from 'react-toastify'
import { Inter } from 'next/font/google'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { cx } from 'class-variance-authority'

import {
  useAuthSignOutService,
  useServerPageLoading,
  useSubscriptionResult,
} from '@/lib/hooks'
import { CookieConsent } from '@/feature/cookies'
import { ErrorBoundary } from '@sentry/nextjs'
import { CrashFallback, Header, PageLoader } from '@/layouts'
import { AuthProvider } from '@/context/AuthContext'
// eslint-disable-next-line import/no-named-as-default
import useAnalytics from '@/lib/analytics/useAnalytics'

import commonEnTranslation from 'src/assets/locales/en/common.json'
import { isDev } from 'src/lib/runtime'

import 'react-toastify/dist/ReactToastify.css'
import 'src/lib/api-client/interceptors'
import 'src/styles/globals.scss'

i18n.use(initReactI18next).init({
  resources: {
    en: {
      common: commonEnTranslation,
    },
  },
  lng: 'en',
  ns: ['common'],
  debug: isDev,
  returnNull: false,
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
})

const inter = Inter({
  subsets: ['latin'],
  weight: ['300', '400', '500', '600'],
  variable: '--font-family',
})

const NoopComponent: React.FC<React.PropsWithChildren> = ({ children }) => (
  // eslint-disable-next-line react/jsx-no-useless-fragment
  <>{children}</>
)

type AppPropsWithProvider = Omit<AppProps, 'Component'> & {
  Component: AppProps['Component'] & {
    provider?: React.FC<React.PropsWithChildren>
  }
}

const App = ({ Component, pageProps }: AppPropsWithProvider) => {
  /**
   * If ".provider" is defined - wrap the whole app in context provider.
   * If it's not - wrap into NoopComponent
   *
   * It's necessary when we want to share context through several pages.
   */
  const ContextProvider = Component.provider || NoopComponent

  const isServerPageLoading = useServerPageLoading()

  const [{ loading: isSignOutLoading }, signOut] = useAuthSignOutService()

  useSubscriptionResult()

  useAnalytics()

  return (
    <HelmetProvider>
      <>
        <Helmet>
          <html
            lang={i18n.language}
            className={cx([inter.className, inter.variable])}
          />
          <title>Socialprofiler</title>
          <link
            rel="apple-touch-icon"
            sizes="180x180"
            href="/favicon/apple-touch-icon.png"
          />
          <link
            rel="icon"
            type="image/png"
            sizes="32x32"
            href="/favicon/favicon-32x32.png"
          />
          <link
            rel="icon"
            type="image/png"
            sizes="16x16"
            href="/favicon/favicon-16x16.png"
          />
          <link
            rel="manifest"
            href="/site.webmanifest"
            crossOrigin="use-credentials"
          />
        </Helmet>
        <ErrorBoundary fallback={CrashFallback}>
          <ToastContainer
            position="top-center"
            transition={Slide}
            closeOnClick={false}
          />
          <AuthProvider>
            {(isUserLoading: boolean) => (
              <>
                <ContextProvider>
                  <main>
                    <Header onSignOut={signOut} />
                    {isServerPageLoading ||
                    isSignOutLoading ||
                    isUserLoading ? (
                      <PageLoader />
                    ) : (
                      <Component {...pageProps} />
                    )}
                  </main>
                </ContextProvider>
                <CookieConsent />
              </>
            )}
          </AuthProvider>
        </ErrorBoundary>
      </>
    </HelmetProvider>
  )
}

// noinspection JSUnusedGlobalSymbols
export default App
