import React from 'react'

import { Button } from '@/components'

import styles from './CookiesModal.module.scss'
import { useTranslation } from 'react-i18next'

type CookiesBannerProps = {
  onClick: (consent: boolean) => void
}

const CookiesModal: React.FC<CookiesBannerProps> = ({ onClick }) => {
  const { t } = useTranslation('', { keyPrefix: 'cookies' })

  return (
    <div className={styles.cookies}>
      <div className={styles.cookies__heading}>
        <h2 className={styles.title}>{t('title')}</h2>
        <p className={styles.message}> {t('message')}</p>
      </div>

      <div className={styles.cookies__controls}>
        <Button
          color="black"
          className={styles.accept}
          onClick={() => onClick(true)}
        >
          {t('buttonAccept')}
        </Button>
        <Button className={styles.link} onClick={() => onClick(false)}>
          {t('buttonEssentials')}
        </Button>
      </div>
    </div>
  )
}

export default CookiesModal
