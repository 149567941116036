import React from 'react'
import { deleteCookie, getCookie, setCookie } from 'cookies-next'
import { useRouter } from 'next/navigation'
import type { User } from '@/lib/api-client/generated'
import { useAuthInfoService } from '@/lib/hooks/api'
import { getCookieDomain } from '@/utils/location'

const SESSION_VERSION = 1

/**
 * Check for session token in cookie and stored version
 */
const isSessionActive = () => {
  const session = getCookie('session')
  const sessionVersion = getCookie('session_version')

  return !!(
    session &&
    sessionVersion &&
    Number(sessionVersion) === SESSION_VERSION
  )
}

type AuthContext = {
  isAuthenticated: boolean
  getUser: () => Promise<User> | null
  setSession: (token: string) => void
  closeSession: () => void
  user?: User
}

const initialValue = {
  isAuthenticated: false,
  getUser: () => null,
  setSession: () => null,
  closeSession: () => null,
}

const AuthContext = React.createContext<AuthContext>(initialValue)

export const useAuthContext = () => React.useContext(AuthContext)

export const AuthProvider: React.FC<{
  children: (isLoading: boolean) => JSX.Element
}> = ({ children }) => {
  const router = useRouter()

  const [{ value: user, loading }, getAuthInfo, reset] = useAuthInfoService()

  const setSession = React.useCallback((token: string) => {
    setCookie('session', token, {
      domain: getCookieDomain(),
    })
    setCookie('session_version', SESSION_VERSION, {
      domain: getCookieDomain(),
    })

    router.refresh()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const closeSession = React.useCallback(() => {
    deleteCookie('session')
    deleteCookie('session_version')

    reset()

    router.push('/login')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const value = React.useMemo(
    () => ({
      isAuthenticated: isSessionActive(),
      getUser: getAuthInfo,
      setSession,
      closeSession,
      user,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user, loading],
  )

  /** Get user info on app mount and active session. */
  React.useEffect(() => {
    if (isSessionActive() && !user && !loading) {
      getAuthInfo()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  return (
    <AuthContext.Provider value={value}>
      {children?.(loading)}
    </AuthContext.Provider>
  )
}
