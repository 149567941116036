import { LoginState } from '@/widgets/Login/model'

export const navItems: Array<{
  title: string
  url: string
}> = [
  {
    title: 'Plans',
    url: '/plans',
  },
  {
    title: 'Report examples',
    url: '/report-examples',
  },
  {
    title: 'FAQ',
    url: '/faq',
  },
]

export const formState: LoginState[] = ['login', 'signUp', 'forgot']
