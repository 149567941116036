import React from 'react'
import useFocus from './use-focus'

const usePasteFromClipboard = (
  inputRef: React.MutableRefObject<HTMLInputElement | null>,
  onPaste: (value: string) => void,
) => {
  const isInputFocused = useFocus(inputRef)

  React.useEffect(() => {
    const pasteFn = (event: ClipboardEvent) => {
      if (isInputFocused) {
        return
      }

      const copiedValue = event.clipboardData?.getData('text/plain')

      if (typeof copiedValue === 'string') {
        onPaste(copiedValue)
      }
    }

    document.addEventListener('paste', pasteFn)

    return () => {
      document.removeEventListener('paste', pasteFn)
    }
  }, [onPaste, isInputFocused])

  return React.useCallback(async () => {
    const copiedValue = await navigator?.clipboard?.readText?.()

    if (typeof copiedValue === 'string') {
      onPaste(copiedValue)
    }
  }, [onPaste])
}

export default usePasteFromClipboard
