import useErrorEffect from '../../use-error-effect'
import { isGeneralError } from '@/utils/api'
import useCustomAsyncFn from '../use-custom-async-fn'
import apiClient from '@/lib/api-client'

/**
 * Service
 */
const useAuthSignOutService = () => {
  const state = useCustomAsyncFn(async () => {
    const result = await apiClient.Auth.postAuthSignout()

    if (isGeneralError(result)) {
      throw result
    }

    return result
  }, [])

  useErrorEffect(state[0].value, state[0].error)

  return state
}

export default useAuthSignOutService
