import React from 'react'
import { VariantProps, cva } from 'class-variance-authority'
import { Icon } from '../Icon'
import { Button } from '../Button'

import styles from './NotificationBox.module.scss'

const notificationBoxStyle = cva(styles.content, {
  variants: {
    direction: {
      column: styles.content_column,
      row: styles.content_row,
    },
    size: {
      md: styles.content_md,
      xl: styles.content_xl,
    },
  },
  defaultVariants: {
    direction: 'column',
    size: 'md',
  },
})

type NotificationBoxProps = React.ComponentPropsWithoutRef<'button'> &
  VariantProps<typeof notificationBoxStyle> & {
    title: string
    description: string
    onClose?: () => void
  }

const NotificationBox = React.forwardRef<HTMLDivElement, NotificationBoxProps>(
  (restProps, ref) => {
    const {
      className,
      children,
      direction,
      size,
      title,
      description,
      onClick,
      onClose,
    } = restProps

    return (
      <div
        className={notificationBoxStyle({ direction, size, className })}
        ref={ref}
      >
        {onClose && (
          <Button
            size="iconSm"
            type="button"
            color="primary"
            aria-label="dialog-close"
            onClick={onClose}
            className={styles.close}
          >
            <Icon name="close" width={24} height={24} className={styles.icon} />
          </Button>
        )}
        <div className={styles.heading}>
          <h3>{title}</h3>
          <p>{description}</p>
        </div>
        <Button type="button" color="black" onClick={onClick}>
          {children}
        </Button>
      </div>
    )
  },
)

NotificationBox.displayName = 'NotificationBox'

export default React.memo(NotificationBox)
