/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GeneralError } from '../models/GeneralError'
import type { StatusResponse } from '../models/StatusResponse'

import type { CancelablePromise } from '../core/CancelablePromise'
import { OpenAPI } from '../core/OpenAPI'
import { request as __request } from '../core/request'

export class SettingsService {
  /**
   * @param requestBody settings update email body
   * @returns any send confirm response
   * @returns GeneralError response for error
   * @throws ApiError
   */
  public static postSettingsEmail(requestBody?: {
    email: string
    password: string
  }): CancelablePromise<
    | (StatusResponse & {
        /**
         * remaining delay in seconds
         */
        remainingDelay?: number
      })
    | GeneralError
  > {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/settings/email',
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * @returns any send confirm response
   * @returns GeneralError response for error
   * @throws ApiError
   */
  public static postSettingsEmailConfirm(): CancelablePromise<
    | (StatusResponse & {
        /**
         * remaining delay in seconds
         */
        remainingDelay?: number
      })
    | GeneralError
  > {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/settings/email/confirm',
      errors: {
        401: `response for error`,
      },
    })
  }

  /**
   * @param requestBody settings update password body
   * @returns StatusResponse operation status response
   * @returns GeneralError response for error
   * @throws ApiError
   */
  public static postSettingsPassword(requestBody?: {
    oldPassword: string
    newPassword: string
  }): CancelablePromise<StatusResponse | GeneralError> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/settings/password',
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * @param requestBody settings update receive emails body
   * @returns StatusResponse operation status response
   * @returns GeneralError response for error
   * @throws ApiError
   */
  public static postSettingsReceive(requestBody?: {
    receive: boolean
  }): CancelablePromise<StatusResponse | GeneralError> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/settings/receive',
      body: requestBody,
      mediaType: 'application/json',
    })
  }
}
