import { MOCKED_API } from '@/constants/config'
import { AuthService } from './generated/services/AuthService'
import { DashboardService } from './generated/services/DashboardService'
import { DataReceivingService } from './generated/services/DataReceivingService'
import { HealthCheckService } from './generated/services/HealthCheckService'
import { IssuesService } from './generated/services/IssuesService'
import { LandingService } from './generated/services/LandingService'
import { ReportCreationService } from './generated/services/ReportCreationService'
import { SettingsService } from './generated/services/SettingsService'
import { DashboardServiceMock, LandingServiceMock } from './mock'
import { StripeService } from './generated'

/** It is necessary to maintain relevance of mocked services for type consistency. */
const MockedService = {
  Dashboard: DashboardServiceMock,
  Landing: LandingServiceMock,
}

export default {
  Dashboard: DashboardService,
  Auth: AuthService,
  DataReceiving: DataReceivingService,
  HealthCheck: HealthCheckService,
  Issues: IssuesService,
  Landing: LandingService,
  ReportCreation: ReportCreationService,
  Settings: SettingsService,
  Stripe: StripeService,

  /** Rewrite only mocked services. */
  ...(MOCKED_API ? MockedService : {}),
}
