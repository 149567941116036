import useErrorEffect from '../../use-error-effect'
import { isGeneralError } from '@/utils/api'
import useCustomAsyncFn from '../use-custom-async-fn'
import useCancelablePromise from '../../use-cancelable-promise'
import { DEFAULT_PROFILES_LIMIT } from '@/constants/config'
import apiClient from '@/lib/api-client'

/**
 * Service for request to search for profiles on all social networks by report id
 */
const useSearchProfilesByReportIdService = (reportId: string) => {
  const [addToCancel, cancel] =
    useCancelablePromise<
      Awaited<ReturnType<typeof apiClient.ReportCreation.getCreate>>
    >()

  const state = useCustomAsyncFn(
    async (limit: number = DEFAULT_PROFILES_LIMIT) => {
      const request = addToCancel(
        apiClient.ReportCreation.getCreate(reportId, limit),
      )

      try {
        const result = await request

        if (isGeneralError(result)) {
          throw result
        }

        return result
      } catch (e) {
        if (request?.isCancelled) {
          return null
        }

        throw e
      }
    },
    [],
  )

  useErrorEffect(state[0].value, state[0].error)

  return [state[0], state[1], cancel] as const
}

export default useSearchProfilesByReportIdService
