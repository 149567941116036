import React from 'react'

import { t } from 'i18next'
import { Helmet } from 'react-helmet-async'
/** TODO: reexport Spinner from index */
import { Spinner } from '@/components/Spinner'
import { cx } from 'class-variance-authority'

import styles from './PageLoader.module.scss'

type PageLoaderProps = {
  onCancel?: () => void
}

const PageLoader: React.FC<PageLoaderProps> = React.memo(({ onCancel }) => {
  return (
    <div className={cx([styles.container])}>
      <Helmet>
        <title>{`Socialprofiler ${t('Loading...')}`}</title>
      </Helmet>
      <Spinner text={t('Loading...')}>
        {onCancel && (
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <a onClick={onCancel}>{t('Cancel')}</a>
        )}
      </Spinner>
    </div>
  )
})

export default PageLoader
