import { throttle } from '@/utils/throttle'
import React from 'react'

const useBreakpoint = () => {
  const [windowSize, setWindowSize] = React.useState<{
    width: number | undefined
    height: number | undefined
  }>({
    width: undefined,
    height: undefined,
  })

  const handleResize = React.useCallback(() => {
    const [func] = throttle(() => {
      setWindowSize({
        width: window.visualViewport?.width,
        height: window.visualViewport?.height,
      })
    }, 100)

    func()
  }, [setWindowSize])

  const isMobile = React.useMemo(
    () =>
      Boolean(
        windowSize.width && windowSize.width > 0 && windowSize.width < 730,
      ),
    [windowSize.width],
  )

  const isTablet = React.useMemo(
    () =>
      Boolean(
        windowSize.width && windowSize.width >= 730 && windowSize.width < 1080,
      ),
    [windowSize.width],
  )

  const isDesktop = React.useMemo(
    () => Boolean(windowSize.width && windowSize.width >= 1080),
    [windowSize.width],
  )

  React.useLayoutEffect(() => {
    window.addEventListener('resize', handleResize)
    handleResize()

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [windowSize.width, handleResize])

  return { isMobile, isTablet, isDesktop }
}

export default useBreakpoint
