import { Source } from '@/lib/api-client/generated'
import { ValueOf } from 'next/dist/shared/lib/constants'

export const sourceForBadConfirm: Source[] = ['ig', 'fb']

export const SocialName = {
  instagram: 'instagram',
  twitter: 'twitter',
  facebook: 'facebook',
  tiktok: 'tiktok',
  x: 'x',
} as const

export type SocialSource = ValueOf<typeof SocialName>

export const SocialNameToCodeMap: Record<SocialSource, Source> = {
  [SocialName.instagram]: 'ig',
  [SocialName.twitter]: 'tw',
  [SocialName.facebook]: 'fb',
  [SocialName.tiktok]: 'tt',
  [SocialName.x]: 'tw',
} as const

export const SocialCodeToNameMap: Record<Source, SocialSource> = {
  ig: SocialName.instagram,
  fb: SocialName.facebook,
  tt: SocialName.tiktok,
  tw: SocialName.x,
} as const

export const social: Source[] = ['ig', 'fb', 'tw', 'tt']
