import React from 'react'
import { useTranslation } from 'react-i18next'

import { Button, Input } from '@/components'
import { useAuthContext } from '@/context/AuthContext'
import { useAuthSignInService } from '@/lib/hooks'

import { useSignInForm } from './hooks'

import type { LoginState } from '../model'

import styles from './SignIn.module.scss'

type SignInProps = {
  onChangeState: (form: LoginState) => void
}

const SignIn: React.FC<SignInProps> = ({ onChangeState }) => {
  const { t } = useTranslation()
  const { setSession } = useAuthContext()

  const { form, validate } = useSignInForm()

  const [{ value, loading }, signIn] = useAuthSignInService()

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault()

    const isValid = validate()

    if (!isValid) {
      return
    }

    const { email, password } = form

    const response = await signIn(
      email.inputState.value,
      password.inputState.value,
    )

    const { token } = response ?? {}

    if (token) {
      setSession(token)
    }
  }

  return (
    <div className={styles.signIn}>
      <form className={styles.signIn__form} onSubmit={handleSubmit}>
        <Input
          className={styles.input}
          label={t('email')}
          type="text"
          placeholder={t('email')}
          required
          {...form.email.inputState}
        />

        <Input
          className={styles.input}
          label={t('LoginFormPasswordFieldLabel')}
          type="password"
          placeholder={t('LoginFormPasswordFieldPlaceholder')}
          {...form.password.inputState}
        />

        <Button
          type="button"
          variant="link"
          className={styles.forgot}
          onClick={() => onChangeState('forgot')}
        >
          Forgot your password?
        </Button>

        <Button
          type="submit"
          className={styles.button}
          color="black"
          /** Loader if  receiving a token or get token and awaiting redirect */
          loading={loading || !!value?.token}
        >
          {t('LoginFormSigninButtonText')}
        </Button>
      </form>
    </div>
  )
}

export default SignIn
