import { isGeneralError } from '@/utils/api'
import useCustomAsyncFn from '../use-custom-async-fn'
import apiClient from '@/lib/api-client'

/**
 * Service for health checking
 */
const useHealthCheckService = () => {
  const state = useCustomAsyncFn(async () => {
    const result = await apiClient.HealthCheck.getHealthcheck()

    if (isGeneralError(result)) {
      throw result
    }

    return result
  }, [])

  return state
}

export default useHealthCheckService
