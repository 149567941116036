import React from 'react'

/** Detect html element focusing. */
const useFocus = (
  ref: React.MutableRefObject<HTMLElement | null>,
  defaultState = false,
) => {
  const node = ref.current

  const isNodeMountedRef = React.useRef<boolean>(!!node)

  const [state, setState] = React.useState(defaultState)

  React.useEffect(() => {
    if (isNodeMountedRef.current || !node) {
      return undefined
    }

    const onFocus = () => setState(true)
    const onBlur = () => setState(false)

    node.addEventListener('focus', onFocus)
    node.addEventListener('blur', onBlur)

    isNodeMountedRef.current = true

    return () => {
      node.removeEventListener('focus', onFocus)
      node.removeEventListener('blur', onBlur)

      isNodeMountedRef.current = false
    }
  }, [node])

  return state
}

export default useFocus
