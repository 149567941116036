import { isGeneralError } from '@/utils/api'
import useCustomAsyncFn from '../use-custom-async-fn'
import apiClient from '@/lib/api-client'
import useErrorEffect from '../../use-error-effect'

/**
 * Service for receiving estimated time on report creation confirm
 */
const useGetCreationEstimatedTimeService = (reportId: string) => {
  const state = useCustomAsyncFn(async () => {
    const result = await apiClient.ReportCreation.getCreateEstimated(reportId)

    if (isGeneralError(result)) {
      throw result
    }

    return result
  }, [])

  useErrorEffect(state[0].value, state[0].error)

  return state
}

export default useGetCreationEstimatedTimeService
