import { type AttributeState, useForm } from '@/lib/hooks'
import { useTranslation } from 'react-i18next'

type RecoveryForm = {
  password: AttributeState<string>
  confirmPass: AttributeState<string>
}

export const useRecoveryForm = () => {
  const { t } = useTranslation()

  return useForm<RecoveryForm>(
    {
      password: {
        inputState: {
          value: '',
        },
        validate: (value) => (value.trim() !== '' ? null : t('EmailPassword')),
      },
      confirmPass: {
        inputState: {
          value: '',
        },
      },
    },
    (formToValidate) => {
      if (
        !formToValidate.confirmPass.inputState.value ||
        !formToValidate.password.inputState.value
      ) {
        return { error: t('login.error.emptyForm'), attribute: 'confirmPass' }
      }

      const isEqualPasswords =
        formToValidate.confirmPass.inputState.value ===
        formToValidate.password.inputState.value

      if (isEqualPasswords) {
        return undefined
      }

      return { error: t('ConfirmError'), attribute: 'confirmPass' }
    },
  )
}
