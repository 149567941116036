import useErrorEffect from '../../use-error-effect'
import type { SignUpRequest } from '@/lib/api-client/generated'
import { isGeneralError } from '@/utils/api'
import useCustomAsyncFn from '../use-custom-async-fn'
import apiClient from '@/lib/api-client'

/**
 * Service
 */
const useAuthSignUpService = () => {
  const state = useCustomAsyncFn(async (requestBody?: SignUpRequest) => {
    const result = await apiClient.Auth.postAuthSignup(requestBody)

    if (isGeneralError(result)) {
      throw result
    }

    return result
  }, [])

  useErrorEffect(state[0].value, state[0].error)

  return state
}

export default useAuthSignUpService
