import useErrorEffect from '../../use-error-effect'
import type { Source, ProfileId, InterestId } from '@/lib/api-client/generated'
import { isGeneralError } from '@/utils/api'
import useCustomAsyncFn from '../use-custom-async-fn'
import apiClient from '@/lib/api-client'

/**
 * Service
 */
const useSendInterestIssueReportService = (
  reportId: string,
  interestId: InterestId,
  source: Source,
) => {
  const state = useCustomAsyncFn(async (profiles: ProfileId[]) => {
    const result = await apiClient.Issues.postReportIssue(
      reportId,
      source,
      interestId,
      { profiles },
    )

    if (isGeneralError(result)) {
      throw result
    }

    return result
  }, [])

  useErrorEffect(state[0].value, state[0].error)

  return state
}

export default useSendInterestIssueReportService
