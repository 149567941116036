import React from 'react'
import { useTranslation } from 'react-i18next'

import { Button, Input } from '@/components'
import { usePasswordRecoveryConfirmService } from '@/lib/hooks'
import { useAuthContext } from '@/context/AuthContext'
import { toaster } from '@/components/Toaster/toaster'

import { useRecoveryForm } from './hooks'

import styles from './Recovery.module.scss'

type RecoveryProps = {
  hash: string
}

const Recovery: React.FC<RecoveryProps> = ({ hash }) => {
  const [{ loading, error }, recovery] = usePasswordRecoveryConfirmService()

  const { setSession } = useAuthContext()

  const { form, validate } = useRecoveryForm()

  const { t } = useTranslation()

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault()

    const isValid = validate()

    if (!isValid) {
      return
    }

    const { confirmPass } = form

    const response = await recovery(hash, {
      password: confirmPass.inputState.value,
    })

    if (response.token) {
      setSession(response.token)

      toaster.success({
        title: t('changePass'),
      })
    }
  }

  return (
    <div className={styles.recovery}>
      <form className={styles.recovery__form} onSubmit={handleSubmit}>
        <Input
          className={styles.input}
          label={t('RecoveryNew')}
          type="password"
          placeholder={t('LoginFormPasswordFieldPlaceholder')}
          {...form.password.inputState}
        />

        <Input
          className={styles.input}
          label={t('RecoveryConfirm')}
          type="password"
          placeholder={t('LoginFormPasswordFieldPlaceholder')}
          {...form.confirmPass.inputState}
          error={error?.message || form.confirmPass.inputState.error}
        />

        <Button
          type="submit"
          className={styles.button}
          color="black"
          loading={loading}
        >
          {t('ConfirmButton')}
        </Button>
      </form>
    </div>
  )
}

export default Recovery
