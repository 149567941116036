import React from 'react'
import type {
  ReportBlured,
  ReportItem,
  ReportSummaryResult,
} from '@/lib/api-client/generated'
import type { SourceMap } from '@/entities'
import type { UseGetReportServiceValue } from '@/lib/hooks'
import { buildSourceMap } from '@/utils/profile'
import { noop } from '@/utils/helper'

export type ReportContextItem = {
  sourceMap: SourceMap<ReportItem>
  isPublic: boolean
  isAccessible: boolean
  blurVariant?: ReportBlured
  summary?: ReportSummaryResult
}

const buildReportContextItem = ({
  profiles,
  summary,
  public: isPublic,
  blured,
}: UseGetReportServiceValue): ReportContextItem => ({
  sourceMap: buildSourceMap(profiles),
  summary: summary?.result,
  isPublic: !!isPublic,
  isAccessible: !!profiles?.length,
  blurVariant: blured,
})

type ReportContext = {
  report: ReportContextItem
  isEmptyReport: boolean
  shouldSubscribe: boolean
  shouldRegister: boolean
  shouldUpgrade: boolean
  shouldBlurReport: boolean
  setReport: (reportResponse: UseGetReportServiceValue) => void
  setSummary: (summary: ReportSummaryResult) => void
}

const initialValue: ReportContext = {
  report: {
    sourceMap: {},
    isPublic: false,
    isAccessible: false,
  },
  isEmptyReport: true,
  shouldSubscribe: false,
  shouldRegister: false,
  shouldUpgrade: false,
  shouldBlurReport: false,
  setReport: noop,
  setSummary: noop,
}

const ReportContext = React.createContext<ReportContext>(initialValue)

export const useReportContext = () => {
  const context = React.useContext(ReportContext)

  if (context.setReport.name === 'noop' && context.setSummary.name === 'noop') {
    throw new Error('Wrap app into <ReportProvider />')
  }

  return context
}

export const ReportProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  // TODO: delete after verification backend blur flow
  // const { isAuthenticated, user } = useAuthContext()

  const [contextReport, setContextReport] = React.useState<ReportContextItem>({
    sourceMap: {},
    isPublic: false,
    isAccessible: false,
  })

  /** TODO: delete after verification backend blur flow
  const shouldSubscribe =
    !user?.subscription.active &&
    isAuthenticated &&
    contextReport.isAccessible &&
    !contextReport.isPublic

  const shouldRegister =
    !isAuthenticated && contextReport.isAccessible && !contextReport.isPublic

  const shouldBlurReport = shouldSubscribe || shouldRegister
  */

  const shouldSubscribe = contextReport.blurVariant === 'unsubscribed'
  const shouldRegister = contextReport.blurVariant === 'unregistered'
  const shouldUpgrade = contextReport.blurVariant === 'needupgrade'

  const shouldBlurReport = shouldSubscribe || shouldRegister || shouldUpgrade

  const setReport = React.useCallback(
    (reportResponse: UseGetReportServiceValue) => {
      setContextReport(buildReportContextItem(reportResponse))
    },
    [],
  )

  const setSummary = React.useCallback((summary: ReportSummaryResult) => {
    setContextReport((prev) => {
      return {
        ...prev,
        summary,
      }
    })
  }, [])

  const value = React.useMemo(
    () => ({
      setReport,
      setSummary,
      report: contextReport,
      isEmptyReport: !Object.values(contextReport.sourceMap).length,
      shouldSubscribe,
      shouldRegister,
      shouldUpgrade,
      shouldBlurReport,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [contextReport, shouldBlurReport, shouldSubscribe, shouldRegister],
  )

  return (
    <ReportContext.Provider value={value}>{children}</ReportContext.Provider>
  )
}
