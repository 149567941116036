import {
  SocialNameToCodeMap,
  SocialSource,
  social,
  sourceForBadConfirm,
} from '@/constants/source'
import { SourceMap } from '@/entities'
import { ProfileBadReason, SearchProfile } from '@/lib/api-client/generated'
import { Source } from '@/lib/api-client/generated/models/Source'

type ProfileUrl = {
  source: Source | null
  username: string
}

const getSourceFromLink = (source?: string): Source | null => {
  if (!source) {
    return null
  }

  if (Object.keys(SocialNameToCodeMap).includes(source)) {
    return SocialNameToCodeMap[source as SocialSource]
  }

  return null
}

export const getProfileFromLink = (profileUrl?: string): ProfileUrl | null => {
  if (!profileUrl) {
    return { username: '', source: null } /** default */
  }

  const regular =
    /^((https?:\/\/(www\.)?)?(twitter|x|instagram|facebook|tiktok)\.com\/@?)?([.\w]+)\/?(\?\S*)?$/i
  const match = profileUrl.trim().match(regular)

  if (!match) {
    return null
  }

  const source = getSourceFromLink(match?.[4])
  const username = match?.[5] ?? '' /** default */

  return { source, username }
}

/** Source map builder. */
export const buildSourceMap = <T extends { source: Source }, K>(
  sourcedList: T[],
  callback?: (sourcedListItem: T) => K,
) =>
  sourcedList.reduce<SourceMap<K extends T ? T : K>>(
    (acc, sourcedListItem) => ({
      ...acc,
      [sourcedListItem.source]: callback?.(sourcedListItem) || sourcedListItem,
    }),
    {},
  )

export const searchProfileWithoutBadReason = (profiles?: SearchProfile[]) =>
  profiles?.find(({ badReason }) => !badReason)

export const isSourceForBadConfirm = (source: Source) =>
  sourceForBadConfirm.includes(source)

export const getProfileBadReason = (
  source: Source,
  badReason?: ProfileBadReason,
  confirmedBadReason?: false | ProfileBadReason,
) => {
  if (!badReason) {
    return null
  }

  if (isSourceForBadConfirm(source)) {
    return confirmedBadReason
  }

  return badReason
}

/** Build list of sources in correct order. */
export const filterSource = (
  sourceList: Source[],
  searchSource?: Source | null,
) =>
  [
    ...(searchSource ? [searchSource] : []),
    ...social.filter((item) => item !== searchSource),
  ].filter((social) => sourceList.includes(social))
