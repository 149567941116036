import useErrorEffect from '../../use-error-effect'
import type { CreateRequest } from '@/lib/api-client/generated'
import { isGeneralError } from '@/utils/api'
import useCustomAsyncFn from '../use-custom-async-fn'
import apiClient from '@/lib/api-client'
import useCancelablePromise from '../../use-cancelable-promise'

/**
 * Service for receiving report id with cached search params
 */
const useGetReportIdService = () => {
  const [addToCancel, cancel] =
    useCancelablePromise<
      Awaited<ReturnType<typeof apiClient.ReportCreation.postCreate>>
    >()

  const state = useCustomAsyncFn(async (body: CreateRequest) => {
    const request = addToCancel(apiClient.ReportCreation.postCreate(body))

    try {
      const result = await request

      if (isGeneralError(result)) {
        throw result
      }

      return result
    } catch (e) {
      if (request?.isCancelled) {
        return null
      }

      throw e
    }
  }, [])

  useErrorEffect(state[0].value, state[0].error)

  return [state[0], state[1], cancel] as const
}

export default useGetReportIdService
