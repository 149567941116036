import { CancelablePromise, GeneralError } from '@/lib/api-client/generated'

const MOCK_RESPONSE_DELAY = 2000

/** Promise for mocking server response. */
export const mockedRequest = <Response>(
  response: Response,
  returnError = false,
) =>
  new CancelablePromise<Response | GeneralError>(
    (resolve, reject, onCancel) => {
      const timeoutId = setTimeout(() => {
        if (returnError) {
          reject({ code: 500, message: 'internal server error' })
        }

        resolve(response)
      }, MOCK_RESPONSE_DELAY)

      onCancel(() => clearTimeout(timeoutId))
    },
  )
