export const MAX_INTERESTS_IN_CATEGORY = 256
export const COLLAPSED_INTERESTS_COUNT = 256
export const GA_TRACKING_ID = 'G-E3R10CB6DC'
export const CATEGORY_OTHER_ID = 50

/** Default number of profiles to get from server. */
export const DEFAULT_PROFILES_LIMIT = 24

/** API Mocks toggle */
export const MOCKED_API = process.env.NEXT_PUBLIC_MOCKED_API === 'true'

export const RECAPTCHA_SITEKEY = process.env.NEXT_PUBLIC_RECAPTCHA_SITEKEY
