import { type AttributeState, useForm } from '@/lib/hooks'
import { useTranslation } from 'react-i18next'

type SignInForm = {
  email: AttributeState<string>
  password: AttributeState<string>
}

export const useSignInForm = () => {
  const { t } = useTranslation()

  return useForm<SignInForm>(
    {
      email: {
        inputState: {
          value: '',
        },
        validate: (value) => {
          if (!value.trim().length) {
            return t('LoginFormWrongNameMessage')
          }

          return null
        },
      },
      password: {
        inputState: {
          value: '',
        },
        validate: (value) => {
          if (!value.trim().length) {
            return 'Empty password'
          }

          return null
        },
      },
    },
    (formToValidate) => {
      if (
        !formToValidate.email.inputState.value.trim() ||
        !formToValidate.password.inputState.value.trim()
      ) {
        return { error: t('LoginFormWrongNameMessage'), attribute: 'email' }
      }

      return undefined
    },
  )
}
