import React from 'react'
import { useTranslation } from 'react-i18next'

import { Button, Input } from '@/components'
import { usePasswordRecoveryService } from '@/lib/hooks'
import type { LoginState } from '../model'
import { useForgotForm } from './hooks'

import styles from './Forgot.module.scss'

type ForgotProps = {
  onChangeState: (form: LoginState) => void
}

const Forgot: React.FC<ForgotProps> = ({ onChangeState }) => {
  const { t } = useTranslation()
  const [isSuccess, setIsSuccess] = React.useState(false)

  const [{ loading }, sendRecoveryMail] = usePasswordRecoveryService()

  const { form, validate } = useForgotForm()

  const handleSend = () =>
    sendRecoveryMail({
      email: form.email.inputState.value,
    })

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault()

    const isValid = validate()

    if (!isValid) {
      return
    }

    const response = await handleSend()

    if (response.status === 'success') {
      setIsSuccess(true)
      /** TODO: добавить тост */
    }
  }

  if (isSuccess) {
    return (
      <div className={styles.signIn}>
        <h1 className={styles.signIn__heading}>{t('reset.title')}</h1>
        <p className={styles.signIn__message}>{t('reset.message')}</p>

        <Button
          type="submit"
          className={styles.button}
          color="black"
          loading={loading}
          onClick={handleSend}
        >
          {t('reset.button')}
        </Button>
      </div>
    )
  }

  return (
    <div className={styles.signIn}>
      <h1 className={styles.signIn__heading}>{t('restore')}</h1>
      <form className={styles.signIn__form} onSubmit={handleSubmit}>
        <Input
          className={styles.input}
          label={t('email')}
          type="text"
          placeholder={t('email')}
          required
          {...form.email.inputState}
        />

        <Button
          type="submit"
          className={styles.button}
          color="black"
          loading={loading}
        >
          {t('ResetButton')}
        </Button>
        <Button
          type="button"
          variant="link"
          className={styles.login}
          onClick={() => onChangeState('login')}
        >
          {t('navbarLogin')}
        </Button>
      </form>
    </div>
  )
}

export default Forgot
