import useErrorEffect from '../../use-error-effect'
import { isGeneralError } from '@/utils/api'
import useCustomAsyncFn from '../use-custom-async-fn'
import { DEFAULT_PROFILES_LIMIT } from '@/constants/config'
import apiClient from '@/lib/api-client'

/**
 * Service for receiving a report on all social networks
 */
const useGetReportService = (reportId: string) => {
  const state = useCustomAsyncFn(
    async (limit: number = DEFAULT_PROFILES_LIMIT) => {
      const result = await apiClient.DataReceiving.getReport(reportId, limit)

      if (isGeneralError(result)) {
        throw result
      }

      return result
    },
    [],
  )

  useErrorEffect(state[0].value, state[0].error)

  return state
}

/** Response of getReport */
export type UseGetReportServiceValue = NonNullable<
  ReturnType<typeof useGetReportService>[0]['value']
>

export default useGetReportService
