import { Icon } from '@/components/Icon'

import styles from './ToastIcon.module.scss'

type ToastIconProps = {
  variant?: 'success' | 'error' | 'warning'
}

const ToastIcon: React.FC<ToastIconProps> = ({ variant }) => {
  return (
    <span className={styles[`${variant}`]}>
      <Icon name="error" className={styles.icon} width={16} height={16} />
    </span>
  )
}

export default ToastIcon
