import React from 'react'

import { Button } from '@/components'

import type { LoginState } from '../model'

import { SignUpForm } from '../SignUp'
import { SignInForm } from '../SignIn'

import styles from './TabForm.module.scss'
import { t } from 'i18next'

type TabFormState = Exclude<LoginState, 'forgot' | 'recovery'>

type TabFormProps = {
  onChangeState: (form: LoginState) => void
  state: TabFormState
}

const TabForm: React.FC<TabFormProps> = ({ onChangeState, state }) => {
  const isLogin = state === 'login'

  return (
    <div className={styles.container}>
      <div className={styles.tabs}>
        <Button
          size="m"
          color={isLogin ? 'white' : 'primary'}
          onClick={() => onChangeState('login')}
        >
          {t('logIn')}
        </Button>
        <Button
          size="m"
          onClick={() => onChangeState('signUp')}
          color={!isLogin ? 'white' : 'primary'}
        >
          {t('SignUp')}
        </Button>
      </div>
      <div className={styles.tabs_content}>
        {isLogin ? (
          <SignInForm onChangeState={onChangeState} />
        ) : (
          <SignUpForm />
        )}
      </div>
    </div>
  )
}

export default TabForm
