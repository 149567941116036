import type { GeneralError, StatusResponse } from '@/lib/api-client/generated'
import { isObject } from './helper'

/** Type guard for General Error. */
export const isGeneralError = <T extends object | string>(
  response: T | GeneralError,
): response is GeneralError =>
  // @ts-ignore
  isObject(response) ? 'code' in response && 'message' in response : false

/** Type guard for StatusResponse error state. */
export const isStatusResponseError = <T>(
  response: StatusResponse | T,
): response is StatusResponse =>
  // @ts-ignore
  isObject(response) ? 'error' in response && 'status' in response : undefined

export const isFulfilledSettledResult = <T>(
  settledResult: PromiseSettledResult<T>,
): settledResult is PromiseFulfilledResult<T> =>
  settledResult.status === 'fulfilled' && 'value' in settledResult
