/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GeneralError } from '../models/GeneralError'
import type { PlanId } from '../models/PlanId'
import type { StartSubscriptionResponse } from '../models/StartSubscriptionResponse'
import type { StatusResponse } from '../models/StatusResponse'
import type { Subscription } from '../models/Subscription'

import type { CancelablePromise } from '../core/CancelablePromise'
import { OpenAPI } from '../core/OpenAPI'
import { request as __request } from '../core/request'

export class StripeService {
  /**
   * @param requestBody
   * @returns string success response
   * @returns GeneralError response for error
   * @throws ApiError
   */
  public static postPaymentsWebhook(
    requestBody?: any,
  ): CancelablePromise<string | GeneralError> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/payments/webhook',
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * @param planId string ID of subscription plan
   * @returns StartSubscriptionResponse id of started stripe session to redirect on
   * @returns GeneralError response for error
   * @throws ApiError
   */
  public static postSubscriptionStart(
    planId: PlanId,
  ): CancelablePromise<StartSubscriptionResponse | GeneralError> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/subscription/start/{planId}',
      path: {
        planId: planId,
      },
      errors: {
        401: `response for error`,
      },
    })
  }

  /**
   * @returns StatusResponse operation status response
   * @returns GeneralError response for error
   * @throws ApiError
   */
  public static postSubscriptionCancel(): CancelablePromise<
    StatusResponse | GeneralError
  > {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/subscription/cancel',
      errors: {
        401: `response for error`,
      },
    })
  }

  /**
   * @returns Subscription subscription info
   * @returns GeneralError response for error
   * @throws ApiError
   */
  public static getSubscriptionInfo(): CancelablePromise<
    Subscription | GeneralError
  > {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/subscription/info',
      errors: {
        401: `response for error`,
      },
    })
  }
}
