/**
 * Custom implementation of useAsuncFn hook from:
 * https://github.com/streamich/react-use/blob/master/src/useAsyncFn.ts
 *
 * `return error` was substitute by `throw error` in `.then()` chain for correct callback error handling.
 */
import React from 'react'

import { useMountedState } from 'react-use'
import type {
  FunctionReturningPromise,
  PromiseType,
} from 'react-use/lib/misc/types'

export type AsyncState<T> =
  | {
      loading: boolean
      error?: undefined
      value?: undefined
    }
  | {
      loading: true
      error?: Error | undefined
      value?: T
    }
  | {
      loading: false
      error: Error
      value?: undefined
    }
  | {
      loading: false
      error?: undefined
      value: T
    }

type StateFromFunctionReturningPromise<T extends FunctionReturningPromise> =
  AsyncState<PromiseType<ReturnType<T>>>

export type AsyncFnReturn<
  T extends FunctionReturningPromise = FunctionReturningPromise,
> = [StateFromFunctionReturningPromise<T>, T, () => void]

export default function useCustomAsyncFn<T extends FunctionReturningPromise>(
  fn: T,
  deps: React.DependencyList = [],
  initialState: StateFromFunctionReturningPromise<T> = { loading: false },
): AsyncFnReturn<T> {
  const lastCallId = React.useRef(0)
  const isMounted = useMountedState()
  const [state, set] =
    React.useState<StateFromFunctionReturningPromise<T>>(initialState)

  const callback = React.useCallback(
    (...args: Parameters<T>): ReturnType<T> => {
      // eslint-disable-next-line no-plusplus
      const callId = ++lastCallId.current

      if (!state.loading) {
        set((prevState) => ({ ...prevState, loading: true }))
      }

      return fn(...args).then(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (value: any) => {
          // eslint-disable-next-line @typescript-eslint/no-unused-expressions
          isMounted() &&
            callId === lastCallId.current &&
            set({ value, loading: false })

          return value
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (error: any) => {
          // eslint-disable-next-line @typescript-eslint/no-unused-expressions
          isMounted() &&
            callId === lastCallId.current &&
            set({ error, loading: false })

          throw error
        },
      ) as ReturnType<T>
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    deps,
  )

  const reset = React.useCallback(() => {
    set(initialState)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return [state, callback as unknown as T, reset]
}
