export type ToastProps = {
  title: string
  message?: string
}

const Toast: React.FC<ToastProps> = ({ title, message }) => {
  return (
    <div>
      <h5>{title}</h5>
      {message && <p>{message}</p>}
    </div>
  )
}

export default Toast
