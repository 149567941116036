import useErrorEffect from '../../use-error-effect'
import { isGeneralError } from '@/utils/api'
import useCustomAsyncFn from '../use-custom-async-fn'
import apiClient from '@/lib/api-client'

/**
 * Service for password recovery confirm
 */
const usePasswordRecoveryConfirmService = () => {
  const state = useCustomAsyncFn(
    async (
      hash: string,
      requestBody?: {
        password: string
      },
    ) => {
      const result = await apiClient.Auth.postAuthRecovery1(hash, requestBody)

      if (isGeneralError(result)) {
        throw result
      }

      return result
    },
    [],
  )

  useErrorEffect(state[0].value, state[0].error)

  return state
}

export default usePasswordRecoveryConfirmService
