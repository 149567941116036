import React from 'react'
import { useRouter } from 'next/router'
import { useGetReportService } from './api'
import {
  type ReportContextItem,
  useReportContext,
} from '@/context/ReportContext/'
import { useMount } from 'react-use'

interface UseReportReturn {
  isLoading: boolean
  isLoaded: boolean
  report?: ReportContextItem
}

const useReport = (reportId: string): UseReportReturn => {
  const { push } = useRouter()

  const { report, isEmptyReport, setReport } = useReportContext()

  const [state, getReport] = useGetReportService(reportId)

  useMount(() => {
    if (isEmptyReport) {
      getReport()
    }
  })

  /** If executed a request to re-receive the report, then write it to the context. */
  React.useEffect(() => {
    if (state.value) {
      setReport(state.value)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.value])

  /** Error check and step back */
  React.useEffect(() => {
    if (state.error) {
      push(`/report/${reportId}/create`)
    }
  }, [push, state.error, reportId])

  return {
    report: isEmptyReport ? undefined : report,
    isLoading: state.loading,
    isLoaded: !!state.value || !isEmptyReport,
  }
}

export default useReport
