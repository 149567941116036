import React, { ComponentPropsWithoutRef } from 'react'
import { cx } from 'class-variance-authority'

import styles from './Checkbox.module.scss'

type CheckboxProps = ComponentPropsWithoutRef<'input'> & {
  sizeLabel?: 'normal' | 'small'
  onCheck?: () => void
  errors?: string
}

type InputRef = HTMLInputElement

const Checkbox = React.forwardRef<InputRef, CheckboxProps>((restProps, ref) => {
  const {
    sizeLabel = 'normal',
    className,
    checked: checkedRest = false,
    errors,
    onCheck,
    children,
    ...props
  } = restProps

  const handleCheck = () => {
    onCheck?.()
  }

  return (
    <div
      className={cx([
        styles.container,
        styles[`${sizeLabel}`],
        { [styles.error]: errors },
        className,
      ])}
    >
      <div className={styles.checkbox}>
        <div className={styles.round}>
          <input
            type="checkbox"
            id="checkbox"
            className={styles.input}
            ref={ref}
            onChange={handleCheck}
            checked={checkedRest}
            {...props}
          />
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label htmlFor="checkbox" className={styles.label} />
        </div>
      </div>
      {children && <div className={styles.content}>{children}</div>}
    </div>
  )
})

Checkbox.displayName = 'Checkbox'

export default React.memo(Checkbox)
