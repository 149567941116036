import useErrorEffect from '../../use-error-effect'
import type { Source, UsernameSearchRequest } from '@/lib/api-client/generated'
import { isGeneralError } from '@/utils/api'
import useCustomAsyncFn from '../use-custom-async-fn'
import apiClient from '@/lib/api-client'

/**
 * Service for request to search for profiles on a specific name
 */
const useSearchProfilesByNameService = (reportId: string) => {
  const state = useCustomAsyncFn(
    async (source: Source, requestBody?: UsernameSearchRequest) => {
      const result = await apiClient.ReportCreation.postCreateSearch(
        reportId,
        source,
        requestBody,
      )

      if (isGeneralError(result)) {
        throw result
      }

      return result
    },
    [],
  )

  useErrorEffect(state[0].value, state[0].error)

  return state
}

export default useSearchProfilesByNameService
