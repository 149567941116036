import React from 'react'
import { cx } from 'class-variance-authority'

import { Button } from '../Button'
import { Icon } from '../Icon'

import styles from './SearchInput.module.scss'

type SearchInputProps = Omit<
  React.ComponentPropsWithoutRef<'div'>,
  'onSubmit'
> & {
  onSubmit: (value: string) => void
  placeholder: string
  onType?: (value: string) => void
  value?: string
  error?: string
}

const SearchInput: React.FC<SearchInputProps> = ({
  value: outerValue,
  onType,
  onSubmit,
  placeholder,
  className,
  error,
}) => {
  const isControlledRef = React.useRef(false)
  const [value, setValue] = React.useState('')

  const handleSearchInterest = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target

    setValue(value)
    onType?.(value)
  }

  const handleClearSearch = () => {
    setValue('')
    onSubmit('')
  }

  const handleSubmitSearch = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key !== 'Enter') {
      return
    }

    const trimmedValue = value.trim()

    if (trimmedValue === '') {
      onSubmit('')

      return
    }

    if (trimmedValue.length >= 2) {
      onSubmit(value)
    }
  }

  /** Effect for updationg controlled input value. */
  React.useEffect(() => {
    if (isControlledRef.current) {
      setValue(outerValue ?? '')
    }
  }, [outerValue])

  /** Effect for catching controlled input state. */
  React.useEffect(() => {
    if (!isControlledRef.current && typeof outerValue === 'string') {
      isControlledRef.current = true

      setValue(outerValue)
    }
  }, [outerValue])

  return (
    <div className={cx([styles.control, { [styles.error]: error }], className)}>
      <input
        className={styles.input}
        placeholder={placeholder}
        onChange={handleSearchInterest}
        onKeyDown={handleSubmitSearch}
        value={value}
      />
      {error && (
        <div className={styles.message}>
          <p>{error}</p>
        </div>
      )}

      <div className={styles.icon}>
        {value && (
          <Button
            type="button"
            className={styles.icon__clear}
            aria-label="search-clear"
            variant="link"
            onClick={handleClearSearch}
          >
            <Icon name="close" width={20} height={20} />
          </Button>
        )}
        <span className={styles.icon__search}>
          <Icon name="search" width={20} height={20} />
        </span>
      </div>
    </div>
  )
}

export default SearchInput
