import Image from 'next/image'
import Link from 'next/link'
import React from 'react'
import { useRouter } from 'next/router'

import { useTranslation } from 'react-i18next'

import logo from 'src/assets/images/logo.svg'

import styles from './Logo.module.scss'

const Logo = () => {
  const { t } = useTranslation()
  const { pathname } = useRouter()

  const redirectLogoPath =
    pathname === '/login'
      ? `${process.env.NEXT_PUBLIC_LANDING_URL}`
      : '/dashboard'

  return (
    <Link className={styles.link} href={redirectLogoPath}>
      <Image
        className={styles.logo}
        src={logo}
        alt={t('logoText')}
        style={{ maxHeight: 'unset' }}
        priority
      />
    </Link>
  )
}

export default Logo
