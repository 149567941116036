import { isGeneralError } from '@/utils/api'
import useCustomAsyncFn from '../use-custom-async-fn'
import apiClient from '@/lib/api-client'
import useErrorEffect from '../../use-error-effect'

/**
 * Service for subscribing to news
 */
const useSubscribeToNewsService = () => {
  const state = useCustomAsyncFn(async (receive?: boolean) => {
    const result = await apiClient.Settings.postSettingsReceive({
      receive: !!receive,
    })

    if (isGeneralError(result)) {
      throw result
    }

    return result
  }, [])

  useErrorEffect(state[0].value, state[0].error)

  return state
}

export default useSubscribeToNewsService
