import React from 'react'

import styles from './Spinner.module.scss'

interface SpinnerProps {
  text?: string
}

const Spinner: React.FC<React.PropsWithChildren<SpinnerProps>> = React.memo(
  ({ text, children }) => {
    return (
      <div className={styles.container}>
        <div className={styles.spinner}>
          <div />
          <div />
          <div />
          <div />
        </div>
        {text && <div>{text}</div>}
        {children}
      </div>
    )
  },
)

export default Spinner
