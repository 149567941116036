/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AddResult } from '../models/AddResult'
import type { CreateRequest } from '../models/CreateRequest'
import type { GeneralError } from '../models/GeneralError'
import type { ProfileId } from '../models/ProfileId'
import type { ProfilesPage } from '../models/ProfilesPage'
import type { ReportId } from '../models/ReportId'
import type { Source } from '../models/Source'
import type { UsernameSearchRequest } from '../models/UsernameSearchRequest'

import type { CancelablePromise } from '../core/CancelablePromise'
import { OpenAPI } from '../core/OpenAPI'
import { request as __request } from '../core/request'

export class ReportCreationService {
  /**
   * @param requestBody search request body
   * @returns any create report response
   * @returns GeneralError response for error
   * @throws ApiError
   */
  public static postCreate(requestBody?: CreateRequest): CancelablePromise<
    | {
        id: ReportId
      }
    | GeneralError
  > {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/create',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `response for error`,
        401: `response for error`,
        402: `response for error`,
        403: `response for error`,
      },
    })
  }

  /**
   * @param reportId string ID of report
   * @param limit items to get
   * @returns any list of matched profiles from source network
   * @returns GeneralError response for error
   * @throws ApiError
   */
  public static getCreate(
    reportId: ReportId,
    limit: number = 8,
  ): CancelablePromise<
    | {
        profiles: Array<ProfilesPage>
      }
    | GeneralError
  > {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/create/{reportId}',
      path: {
        reportId: reportId,
      },
      query: {
        limit: limit,
      },
      errors: {
        400: `response for error`,
        401: `response for error`,
        402: `response for error`,
        403: `response for error`,
      },
    })
  }

  /**
   * @param reportId string ID of report
   * @param source source of report
   * @param limit items to get
   * @param offset items to skip
   * @returns ProfilesPage page of list of matched profiles from source network
   * @returns GeneralError response for error
   * @throws ApiError
   */
  public static getCreate1(
    reportId: ReportId,
    source: Source,
    limit: number = 8,
    offset?: number,
  ): CancelablePromise<ProfilesPage | GeneralError> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/create/{reportId}/{source}',
      path: {
        reportId: reportId,
        source: source,
      },
      query: {
        limit: limit,
        offset: offset,
      },
      errors: {
        400: `either \`source\` & \`username\` or \`name\` + \`age\` + \`state\` (\`age\`/\`state\` optionally) is required`,
        401: `response for error`,
        403: `response for error`,
      },
    })
  }

  /**
   * @param reportId string ID of report
   * @param source source of report
   * @param requestBody source username search request body
   * @returns ProfilesPage page of list of matched profiles from source network
   * @returns GeneralError response for error
   * @throws ApiError
   */
  public static postCreateSearch(
    reportId: ReportId,
    source: Source,
    requestBody?: UsernameSearchRequest,
  ): CancelablePromise<ProfilesPage | GeneralError> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/create/{reportId}/{source}/search',
      path: {
        reportId: reportId,
        source: source,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `response for error`,
        401: `response for error`,
        402: `response for error`,
        403: `response for error`,
      },
    })
  }

  /**
   * @param reportId string ID of report
   * @returns any estimated time for follows loading
   * @returns GeneralError response for error
   * @throws ApiError
   */
  public static getCreateEstimated(reportId: ReportId): CancelablePromise<
    | {
        time: number
      }
    | GeneralError
  > {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/create/{reportId}/estimated',
      path: {
        reportId: reportId,
      },
      errors: {
        400: `either \`source\` & \`username\` or \`name\` + \`age\` + \`state\` (\`age\`/\`state\` optionally) is required`,
        401: `response for error`,
        403: `response for error`,
      },
    })
  }

  /**
   * @param reportId string ID of report
   * @param source source of report
   * @param requestBody add profile to reports request
   * @returns AddResult response for add profile endpoint
   * @returns GeneralError response for error
   * @throws ApiError
   */
  public static postCreateAdd(
    reportId: ReportId,
    source: Source,
    requestBody?: {
      id?: ProfileId
      unloaded?: boolean
    },
  ): CancelablePromise<AddResult | GeneralError> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/create/{reportId}/{source}/add',
      path: {
        reportId: reportId,
        source: source,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `response for error`,
        403: `response for error`,
        404: `profile not found`,
      },
    })
  }
}
