import React from 'react'

export function useOutsideClick<T extends HTMLElement>(
  ref: React.MutableRefObject<T | null>,
  onClickOut: () => void,
) {
  React.useEffect(() => {
    function handleClickOutside({ target }: MouseEvent) {
      if (ref.current && !ref.current.contains(target as Node)) {
        onClickOut?.()
      }
    }

    document.addEventListener('click', handleClickOutside, true)

    return () => document.removeEventListener('click', handleClickOutside, true)
  }, [onClickOut, ref])
}
