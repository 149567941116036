import useErrorEffect from '../../use-error-effect'
import type { Source } from '@/lib/api-client/generated'
import { isGeneralError } from '@/utils/api'
import useCustomAsyncFn from '../use-custom-async-fn'
import { DEFAULT_PROFILES_LIMIT } from '@/constants/config'
import apiClient from '@/lib/api-client'

/**
 * Service for receiving a report on a specific social network
 */
const useGetReportBySourceService = (reportId: string) => {
  const state = useCustomAsyncFn(
    async (source: Source, limit: number = DEFAULT_PROFILES_LIMIT) => {
      const result = await apiClient.DataReceiving.getReport1(
        reportId,
        source,
        limit,
      )

      if (isGeneralError(result)) {
        throw result
      }

      return result
    },
    [],
  )

  useErrorEffect(state[0].value, state[0].error)

  return state
}

export default useGetReportBySourceService
