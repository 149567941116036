/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GeneralError } from '../models/GeneralError'
import type { ReportId } from '../models/ReportId'
import type { ReportInfo } from '../models/ReportInfo'
import type { StatusResponse } from '../models/StatusResponse'

import type { CancelablePromise } from '../core/CancelablePromise'
import { OpenAPI } from '../core/OpenAPI'
import { request as __request } from '../core/request'

export class DashboardService {
  /**
   * @returns ReportInfo list of user reports
   * @returns GeneralError response for error
   * @throws ApiError
   */
  public static getDashboardReports(): CancelablePromise<
    Array<ReportInfo> | GeneralError
  > {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/dashboard/reports',
    })
  }

  /**
   * @param reportId string ID of report
   * @returns StatusResponse operation status response
   * @returns GeneralError response for error
   * @throws ApiError
   */
  public static postDashboardDelete(
    reportId: ReportId,
  ): CancelablePromise<StatusResponse | GeneralError> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/dashboard/delete/{reportId}',
      path: {
        reportId: reportId,
      },
    })
  }

  /**
   * @param reportId string ID of report
   * @returns StatusResponse operation status response
   * @returns GeneralError response for error
   * @throws ApiError
   */
  public static postDashboardShare(
    reportId: ReportId,
  ): CancelablePromise<StatusResponse | GeneralError> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/dashboard/share/{reportId}',
      path: {
        reportId: reportId,
      },
    })
  }
}
