/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { mockedRequest } from '../utils'
import type {
  CancelablePromise,
  DashboardService,
  GeneralError,
  OperationStatus,
  PlanId,
  ReportId,
  ReportInfo,
  StartSubscriptionResponse,
} from '@/lib/api-client/generated'

export class DashboardServiceMock {
  public static postDashboardShare(_reportId: ReportId) {
    return mockedRequest<
      Awaited<ReturnType<typeof DashboardService.postDashboardShare>>
    >({
      status: 'success',
    })
  }

  public static postDashboardDelete(_reportId: ReportId) {
    return mockedRequest<
      Awaited<ReturnType<typeof DashboardService.postDashboardDelete>>
    >({
      status: 'success',
    })
  }

  public static getDashboardReports() {
    return mockedRequest<
      Awaited<ReturnType<typeof DashboardService.getDashboardReports>>
    >([
      {
        id: 'reportId_1',
        title: 'Elon Reeve Musk, 53, Washington',
        created: '2024-12-15T12:11:59Z',
        image: '',
        sources: ['ig'],
      },
      {
        id: 'reportId_2',
        title: 'Donald John Trump, 78, Illinois',
        created: '2024-12-15T13:11:59Z',
        image: '',
        sources: ['ig'],
      },
      {
        id: 'reportId_3',
        title: 'Joseph Robinette «Joe» Biden, 81, Illinois',
        created: '2024-11-15T14:11:59Z',
        image: '',
        sources: ['ig'],
      },
      {
        id: 'reportId_4',
        title: 'Samuel Harris Altma, 53, Pennsylvania',
        created: '2024-11-15T15:11:59Z',
        image: '',
        sources: ['ig'],
      },
      {
        id: 'reportId_5',
        title: 'Elon Reeve Musk, 53, Washington',
        created: '2024-09-15T16:11:59Z',
        image: '',
        sources: ['ig'],
      },
      {
        id: 'reportId_6',
        title: 'Elon Reeve Musk, 53, Washington',
        created: '2024-09-15T17:11:59Z',
        image: '',
        sources: ['ig'],
      },
    ])
  }
}
