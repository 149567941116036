/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { mockedRequest } from '../utils'
import type {
  CancelablePromise,
  GeneralError,
  LandingService,
  Question,
  ReportInfo,
  State,
  SubscriptionPlan,
} from '@/lib/api-client/generated'

export class LandingServiceMock {
  public static getStates() {
    return mockedRequest<Awaited<ReturnType<typeof LandingService.getStates>>>([
      {
        abbr: 'AL',
        title: 'Alabama',
      },
      {
        abbr: 'KY',
        title: 'Kentucky',
      },
      {
        abbr: 'OH',
        title: 'Ohio',
      },
      {
        abbr: 'AK',
        title: 'Alaska',
      },
      {
        abbr: 'LA',
        title: 'Louisiana',
      },
      {
        abbr: 'OK',
        title: 'Oklahoma',
      },
      {
        abbr: 'AZ',
        title: 'Arizona',
      },
      {
        abbr: 'ME',
        title: 'Maine',
      },
      {
        abbr: 'OR',
        title: 'Oregon',
      },
      {
        abbr: 'AR',
        title: 'Arkansas',
      },
      {
        abbr: 'MD',
        title: 'Maryland',
      },
      {
        abbr: 'PA',
        title: 'Pennsylvania',
      },
      {
        abbr: 'AS',
        title: 'American Samoa',
      },
      {
        abbr: 'MA',
        title: 'Massachusetts',
      },
      {
        abbr: 'PR',
        title: 'Puerto Rico',
      },
      {
        abbr: 'CA',
        title: 'California',
      },
      {
        abbr: 'MI',
        title: 'Michigan',
      },
      {
        abbr: 'RI',
        title: 'Rhode Island',
      },
      {
        abbr: 'CO',
        title: 'Colorado',
      },
      {
        abbr: 'MN',
        title: 'Minnesota',
      },
      {
        abbr: 'SC',
        title: 'South Carolina',
      },
      {
        abbr: 'CT',
        title: 'Connecticut',
      },
      {
        abbr: 'MS',
        title: 'Mississippi',
      },
      {
        abbr: 'SD',
        title: 'South Dakota',
      },
      {
        abbr: 'DE',
        title: 'Delaware',
      },
      {
        abbr: 'MO',
        title: 'Missouri',
      },
      {
        abbr: 'TN',
        title: 'Tennessee',
      },
      {
        abbr: 'DC',
        title: 'District of Columbia',
      },
      {
        abbr: 'MT',
        title: 'Montana',
      },
      {
        abbr: 'TX',
        title: 'Texas',
      },
      {
        abbr: 'FL',
        title: 'Florida',
      },
      {
        abbr: 'NE',
        title: 'Nebraska',
      },
      {
        abbr: 'TT',
        title: 'Trust Territories',
      },
      {
        abbr: 'GA',
        title: 'Georgia',
      },
      {
        abbr: 'NV',
        title: 'Nevada',
      },
      {
        abbr: 'UT',
        title: 'Utah',
      },
      {
        abbr: 'GU',
        title: 'Guam',
      },
      {
        abbr: 'NH',
        title: 'New Hampshire',
      },
      {
        abbr: 'VT',
        title: 'Vermont',
      },
      {
        abbr: 'HI',
        title: 'Hawaii',
      },
      {
        abbr: 'NJ',
        title: 'New Jersey',
      },
      {
        abbr: 'VA',
        title: 'Virginia',
      },
      {
        abbr: 'ID',
        title: 'Idaho',
      },
      {
        abbr: 'NM',
        title: 'New Mexico',
      },
      {
        abbr: 'VI',
        title: 'Virgin Islands',
      },
      {
        abbr: 'IL',
        title: 'Illinois',
      },
      {
        abbr: 'NY',
        title: 'New York',
      },
      {
        abbr: 'WA',
        title: 'Washington',
      },
      {
        abbr: 'IN',
        title: 'Indiana',
      },
      {
        abbr: 'NC',
        title: 'North Carolina',
      },
      {
        abbr: 'WV',
        title: 'West Virginia',
      },
      {
        abbr: 'IA',
        title: 'Iowa',
      },
      {
        abbr: 'ND',
        title: 'North Dakota',
      },
      {
        abbr: 'WI',
        title: 'Wisconsin',
      },
      {
        abbr: 'KS',
        title: 'Kansas',
      },
      {
        abbr: 'MP',
        title: 'Northern Mariana Islands',
      },
      {
        abbr: 'WY',
        title: 'Wyoming',
      },
    ])
  }

  public static getExamples() {
    return mockedRequest<
      Awaited<ReturnType<typeof LandingService.getExamples>>
    >([
      {
        id: 'reportId_1',
        title: 'Elon Reeve Musk, 53, Washington',
        created: '2024-11-15T12:11:59Z',
        image: '',
        sources: ['ig'],
      },
      {
        id: 'reportId_2',
        title: 'Donald John Trump, 78, Illinois',
        created: '2024-11-15T13:11:59Z',
        image: '',
        sources: ['ig'],
      },
      {
        id: 'reportId_3',
        title: 'Joseph Robinette «Joe» Biden, 81, Illinois',
        created: '2024-11-15T14:11:59Z',
        image: '',
        sources: ['ig'],
      },
      {
        id: 'reportId_4',
        title: 'Samuel Harris Altma, 53, Pennsylvania',
        created: '2024-11-15T15:11:59Z',
        image: '',
        sources: ['ig'],
      },
      {
        id: 'reportId_5',
        title: 'Elon Reeve Musk, 53, Washington',
        created: '2024-11-15T16:11:59Z',
        image: '',
        sources: ['ig'],
      },
      {
        id: 'reportId_6',
        title: 'Elon Reeve Musk, 53, Washington',
        created: '2024-11-15T17:11:59Z',
        image: '',
        sources: ['ig'],
      },
    ])
  }

  public static getPlans() {
    return mockedRequest<Awaited<ReturnType<typeof LandingService.getPlans>>>([
      {
        title: 'Personal + AI Summary analytics',
        price: 6.99,
        reports: 1,
        current: true,
        id: '1',
        savePerReport: 0,
      },
      {
        title: 'Small business + AI Summary analytics',
        price: 19.99,
        reports: 5,
        id: '2',
        savePerReport: 14.96,
      },
      {
        title: 'Personal',
        price: 69.99,
        reports: 10,
        id: '3',
        savePerReport: 14.96,
      },
      {
        title: 'Small business',
        price: 6.99,
        reports: 50,
        id: '3',
        savePerReport: 14.96,
      },
      {
        title: 'Professional',
        price: 6.99,
        reports: 100,
        id: '4',
        savePerReport: 14.96,
      },
      {
        title: 'Personal + AI Summary analytics',
        price: 6.99,
        reports: 500,
        id: '5',
        savePerReport: 14.96,
      },
    ])
  }

  public static getCookies() {
    return mockedRequest<Awaited<ReturnType<typeof LandingService.getCookies>>>(
      {
        sended: true,
        accepted: true,
      },
    )
  }

  public static postCookies(_requestBody?: boolean) {
    return mockedRequest<
      Awaited<ReturnType<typeof LandingService.postCookies>>
    >({
      code: 1234,
      message: 'message',
      status: 'success',
    })
  }
}
