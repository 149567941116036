import React from 'react'
import styles from './Input.module.scss'
import { VariantProps, cva, cx } from 'class-variance-authority'
import { Icon, IconNames } from '../Icon'

const inputStyle = cva(styles.field, {
  variants: {
    variant: {
      group: styles.field_group,
      single: styles.field_single,
    },
    position: {
      center: styles.field_positionCenter,
      left: styles.field_positionLeft,
      right: styles.field_positionRight,
    },
    direction: {
      row: styles.field_directionRow,
      column: styles.field_directionColumn,
    },
  },
  defaultVariants: {
    variant: 'single',
    position: 'center',
    direction: 'column',
  },
})

type InputProps = VariantProps<typeof inputStyle> &
  React.ComponentPropsWithoutRef<'input'> & {
    label: string
    placeholder: string
    error?: string
    iconName?: IconNames
  }

type InputRef = HTMLInputElement

const Input = React.forwardRef<InputRef, InputProps>((restProps, ref) => {
  const {
    iconName,
    position,
    variant,
    direction,
    className,
    required,
    placeholder,
    onChange,
    value,
    label,
    error,
    ...props
  } = restProps
  const id = React.useId()

  return (
    <div
      // className={cx([styles.field, className])}
      className={inputStyle({ variant, position, direction, className })}
    >
      <label
        className={cx([
          'label',
          styles.label,
          { [styles.label__icon]: iconName },
        ])}
        htmlFor={id}
      >
        <span>{label}</span>
      </label>
      <div
        className={cx([styles.control, { [styles.control_hasIcon]: iconName }])}
      >
        <div
          className={cx([
            styles.background,
            { [styles.background__error]: error },
          ])}
        >
          <input
            id={id}
            className={styles.input}
            value={value}
            onChange={onChange}
            ref={ref}
            placeholder={placeholder}
            {...props}
          />
          {iconName && (
            <span className={styles.icon}>
              <Icon name={iconName} width={24} height={24} />
            </span>
          )}
        </div>
        {error && (
          <div className={styles.error}>
            <p>{error}</p>
          </div>
        )}
      </div>
    </div>
  )
})

Input.displayName = 'Input'

export default React.memo(Input)
