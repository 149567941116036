import React from 'react'
import { useSearchParams } from 'next/navigation'

import { formState } from '@/constants/navigation'

import { ForgotForm } from './Forgot'
import { Recovery } from './Recovey'

import type { LoginState } from './model'
import { PageLoader } from '@/layouts'
import { usePasswordRecoveryHashValidationService } from '@/lib/hooks'
import TabForm from './TabForm/TabForm'

const LoginForm: React.FC = () => {
  const [state, setState] = React.useState<LoginState>('login')
  const [hash, setHash] = React.useState<string | undefined>(undefined)

  const searchParams = useSearchParams()

  const [, validateHashFn] = usePasswordRecoveryHashValidationService()

  const validateHash = React.useCallback(
    async (stateParam: LoginState, hashParam: string) => {
      const response = await validateHashFn(hashParam)

      /** Go to recovery page */
      if (response.status === 'success') {
        setState(stateParam)
        setHash(hashParam)
        /** Notify about invalid link. */
      } else {
        /** implementation */
      }
    },
    [validateHashFn],
  )

  React.useEffect(() => {
    const stateParam = searchParams.get('state') as LoginState | null
    const hashParam = searchParams.get('hash')

    if (!stateParam) {
      return
    }

    if (formState.includes(stateParam)) {
      setState(stateParam)
    }

    /** Go to the recovery page only if there are hash params and validation hash is success */
    if (hashParam && stateParam === 'recovery') {
      validateHash(stateParam, hashParam)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams])

  const handleChangeState = (form: LoginState) => {
    setState(form)
  }

  if (state === 'forgot') {
    return <ForgotForm onChangeState={handleChangeState} />
  }

  if (state === 'signUp' || state === 'login') {
    return <TabForm onChangeState={handleChangeState} state={state} />
  }

  if (state === 'recovery' && hash) {
    return <Recovery hash={hash} />
  }

  return <PageLoader />
}

export default LoginForm
