import useStoreHydration from '@/stores/use-store-hydration'
import React from 'react'

const useHydratedEffect = <T>(cb: () => void, store: T) => {
  const isHydrated = useStoreHydration(store)

  React.useEffect(() => {
    if (isHydrated) {
      cb()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isHydrated])

  return isHydrated
}

export default useHydratedEffect
