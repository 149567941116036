import { isGeneralError } from '@/utils/api'
import useCustomAsyncFn from '../use-custom-async-fn'
import apiClient from '@/lib/api-client'

/**
 * Service
 */
const useGetReportCheckService = () => {
  const state = useCustomAsyncFn(async (reportId: string) => {
    const result = await apiClient.DataReceiving.getCheck(reportId)

    if (isGeneralError(result)) {
      throw result
    }

    return result
  }, [])

  return state
}

export default useGetReportCheckService
