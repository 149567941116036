import * as React from 'react'
import { cx } from 'class-variance-authority'

import { State } from '@/lib/api-client/generated'

import { Icon } from '../Icon'
import { AutocompleteList } from './AutocompleteList'

import { useOutsideClick } from './use-outside-click'

import styles from './Autocomplete.module.scss'

type AutocompleteProps = {
  label: string
  options: State[]
  placeholder?: string
  onChange: (value: string) => void
  variant: 'group' | 'single'
  onFocus?: React.FocusEventHandler<HTMLInputElement>
}

export const Autocomplete: React.FC<AutocompleteProps> = ({
  label,
  options,
  placeholder,
  variant,
  onFocus,
  onChange,
}) => {
  const [open, setOpen] = React.useState(false)

  const [value, setValue] = React.useState('')

  const [list, setList] = React.useState(options)

  const [suggestionIndex, setSuggestionIndex] = React.useState<number | null>(
    null,
  )

  const ref = React.useRef<HTMLDivElement | null>(null)

  const handleChangeAccount = (props: State | null) => {
    if (!props?.title) {
      return
    }

    setValue(props.title)
    setOpen(false)

    onChange(props.abbr ?? '')
  }

  const id = React.useId()

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value

    setValue(inputValue)

    const updateOptions = options.filter((item) =>
      item.title.toLowerCase().includes(inputValue.toLowerCase()),
    )

    if (!updateOptions.length) {
      setList([])

      return
    }

    setList(updateOptions)
  }

  React.useEffect(() => {
    if (!list.length) {
      setList(options)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options])

  const handleKeyDown = (e: React.KeyboardEvent<HTMLElement>) => {
    /** UP ARROW */
    switch (e.code) {
      case 'ArrowUp':
        e?.preventDefault()

        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        !suggestionIndex
          ? setSuggestionIndex(list.length - 1)
          : setSuggestionIndex(suggestionIndex - 1)
        break

      case 'ArrowDown':
        e?.preventDefault()
        if (suggestionIndex === null) {
          setSuggestionIndex(0)

          return
        }
        if (suggestionIndex >= list.length - 1) {
          setSuggestionIndex(0)

          return
        }

        setSuggestionIndex(suggestionIndex + 1)
        break

      case 'Enter':
        if (suggestionIndex !== null) {
          setSuggestionIndex(null)
          handleChangeAccount(list[suggestionIndex])
        }
        break

      default:
        break
    }
  }

  useOutsideClick(ref, () => {
    setOpen(false)

    if (!list.length) {
      setValue('')
      setList(options)
    }
  })

  return (
    <div className={styles.field}>
      <label className={styles.label} htmlFor={id}>
        <span>{label}</span>
      </label>

      <div
        className={cx(
          [styles.select],
          { [styles.open]: open },
          styles[variant],
        )}
        ref={ref}
      >
        <div className={styles.select__container} onFocus={() => setOpen(true)}>
          <div className={styles.select__header}>
            <input
              id={id}
              className={styles.input}
              value={value}
              placeholder={placeholder}
              onChange={handleChange}
              onKeyDown={handleKeyDown}
              onFocus={onFocus}
            />
            <div
              className={styles.select__control}
              onClick={() => setOpen((prev) => !prev)}
            >
              <Icon
                name={open ? 'arrowUp' : 'arrowDown'}
                className={styles.icon}
                width={22}
                height={22}
              />
            </div>
          </div>
          {open && (
            <AutocompleteList
              list={list}
              onClick={handleChangeAccount}
              suggestionIndex={suggestionIndex}
              onKeyDown={handleKeyDown}
            />
          )}
        </div>
      </div>
    </div>
  )
}
