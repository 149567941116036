import type { ReportId } from '@/lib/api-client/generated'
import { isGeneralError } from '@/utils/api'
import useCustomAsyncFn from '../use-custom-async-fn'
import useErrorEffect from '../../use-error-effect'
import apiClient from '@/lib/api-client'

/**
 * Service for sharing report
 */
const useDashboardShareService = () => {
  const state = useCustomAsyncFn(async (reportId: ReportId) => {
    const result = await apiClient.Dashboard.postDashboardShare(reportId)

    if (isGeneralError(result)) {
      throw result
    }

    return result
  }, [])

  useErrorEffect(state[0].value, state[0].error)

  return state
}

export default useDashboardShareService
