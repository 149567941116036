import React from 'react'
import { useTranslation } from 'react-i18next'
import ReCAPTCHA from 'react-google-recaptcha'
import Link from 'next/link'

import { Button, Checkbox, Input } from '@/components'
import { useAuthContext } from '@/context/AuthContext'
import { useAuthSignUpService } from '@/lib/hooks'
import { RECAPTCHA_SITEKEY } from '@/constants/config'

import { useSignUpForm } from './hooks'

import styles from './SignUp.module.scss'

const SignUp: React.FC = () => {
  const reCaptchaRef = React.useRef<ReCAPTCHA | null>(null)

  const { t } = useTranslation()
  const { setSession } = useAuthContext()

  const { form, validate } = useSignUpForm()

  const [{ value, loading }, signUp] = useAuthSignUpService()

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault()

    const isValid = validate()

    if (!isValid) {
      return
    }

    /** reset captcha */
    reCaptchaRef.current?.reset()

    const captchaToken = await reCaptchaRef.current?.executeAsync()

    if (!captchaToken || !reCaptchaRef.current) {
      return
    }

    const { password, email } = form

    /** token - dummy value. */
    const response = await signUp({
      password: password.inputState.value,
      email: email.inputState.value,
      token: captchaToken,
    })

    const { token } = response ?? {}

    if (token) {
      setSession(token)
    }
  }

  return (
    <div className={styles.signIn}>
      <form className={styles.signIn__form} onSubmit={handleSubmit}>
        <Input
          className={styles.input}
          label={t('email')}
          type="text"
          placeholder={t('email')}
          required
          {...form.email.inputState}
        />

        <Input
          className={styles.input}
          label={t('LoginFormPasswordFieldLabel')}
          type="password"
          placeholder={t('LoginFormPasswordFieldPlaceholder')}
          {...form.password.inputState}
        />

        <Input
          className={styles.input}
          label={t('ConfirmButton')}
          type="password"
          placeholder={t('ConfirmButton')}
          {...form.confirmPass.inputState}
        />

        <div className={styles.toc}>
          <Checkbox
            checked={form.isAcceptTerms.inputState.value}
            onChange={form.isAcceptTerms.inputState.onChange}
            sizeLabel="small"
            errors={form.isAcceptTerms.inputState.error}
          >
            I accept the
            <Link
              target="_blank"
              href={`${process.env.NEXT_PUBLIC_LANDING_URL}info/terms-of-service`}
              className={styles.toc__link}
            >
              Terms of Use
            </Link>
            and
            <Link
              target="_blank"
              href={`${process.env.NEXT_PUBLIC_LANDING_URL}info/private-policy`}
              className={styles.toc__link}
            >
              Private Policy
            </Link>
          </Checkbox>
        </div>

        <Button
          type="submit"
          className={styles.button}
          color="black"
          /** Loader if  receiving a token or get token and awaiting redirect */
          loading={loading || !!value?.token}
        >
          {t('SignUp')}
        </Button>
      </form>

      <div className={styles.signIn__footer}>
        {RECAPTCHA_SITEKEY && (
          <ReCAPTCHA
            ref={reCaptchaRef}
            sitekey={RECAPTCHA_SITEKEY}
            size="invisible"
          />
        )}
      </div>
    </div>
  )
}

export default SignUp
