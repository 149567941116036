'use client'

import React from 'react'
import { useMountedState } from 'react-use'
import { useTranslation } from 'react-i18next'
import { cx } from 'class-variance-authority'

import { useAuthContext } from '@/context/AuthContext'
import { Logo } from './Logo'
import { useRenderOnMount } from '@/lib/hooks'
import { Navigation } from './Navigation'

import styles from './Header.module.scss'

type HeaderProps = {
  onSignOut: () => Promise<string>
}

const Header: React.FC<HeaderProps> = React.memo(({ onSignOut }) => {
  const { t } = useTranslation()
  const [menuActive, setMenuActive] = React.useState(false)
  const { isAuthenticated, closeSession } = useAuthContext()

  const isMounted = useMountedState()

  const handleLogout = async () => {
    try {
      await onSignOut()
    } finally {
      setMenuActive(false)
      closeSession()
    }
  }

  useRenderOnMount()

  return (
    <div
      className={styles.header}
      role="navigation"
      aria-label="main navigation"
    >
      <div className={styles.header__brand}>
        <div>
          <Logo />
        </div>

        <Navigation />

        {isAuthenticated && isMounted() && (
          // eslint-disable-next-line jsx-a11y/interactive-supports-focus
          <div
            role="button"
            className={cx([
              styles.burger,
              { [styles.burger__active]: menuActive },
            ])}
            aria-label="menu"
            aria-expanded="false"
            data-target="navbar"
            onClick={() => setMenuActive(!menuActive)}
          >
            <span aria-hidden />
            <span aria-hidden />
            <span aria-hidden />
          </div>
        )}
      </div>

      {isAuthenticated && isMounted() && (
        <div
          id="navbar"
          className={cx(['navbar-menu', { 'is-active': menuActive }])}
        >
          <div className="navbar-start">
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a className="navbar-item" onClick={handleLogout}>
              {t('navbarLogout')}
            </a>
          </div>
        </div>
      )}
    </div>
  )
})

export default Header
