import React from 'react'

import { cx } from 'class-variance-authority'

import { State } from '@/lib/api-client/generated'

import styles from './AutocompleteList.module.scss'

type AutocompleteListProps = {
  list: State[]
  onClick: (props: State | null) => void
  suggestionIndex: number | null
  onKeyDown: (e: React.KeyboardEvent<HTMLElement>) => void
}

export const AutocompleteList: React.FC<AutocompleteListProps> = ({
  list,
  onClick,
  suggestionIndex,
  onKeyDown,
}) => {
  const ref = React.useRef<HTMLUListElement | null>(null)

  React.useEffect(() => {
    if (suggestionIndex !== null) {
      const childArray = Array.from(
        ref.current?.childNodes || [],
      ) as HTMLButtonElement[]

      childArray[suggestionIndex].focus()
    }
  }, [suggestionIndex])

  return (
    <ul className={styles.list} ref={ref} onKeyDown={onKeyDown}>
      {list.map((props, index) => (
        <li
          key={props.abbr}
          onClick={() => onClick(props)}
          className={cx([
            styles.item,
            { [styles.item_hover]: index === suggestionIndex },
          ])}
          tabIndex={0}
          // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
          role="button"
        >
          <p className={styles.heading}>{props.title}</p>
        </li>
      ))}
      {!list.length && (
        <li className={cx([styles.item, styles.item_disable])}>
          <p className={styles.heading}>No options</p>
        </li>
      )}
    </ul>
  )
}
