import { isGeneralError } from '@/utils/api'
import useCustomAsyncFn from '../use-custom-async-fn'
import useErrorEffect from '../../use-error-effect'
import apiClient from '@/lib/api-client'

/**
 * Service for updating email in settings
 */
const useUpdateEmailService = () => {
  const state = useCustomAsyncFn(
    async (requestBody?: { email: string; password: string }) => {
      const result = await apiClient.Settings.postSettingsEmail(requestBody)

      if (isGeneralError(result)) {
        throw result
      }

      return result
    },
    [],
  )

  useErrorEffect(state[0].value, state[0].error)

  return state
}

export default useUpdateEmailService
