import { toaster } from '@/components/Toaster/toaster'
import { t } from 'i18next'
import { useSearchParams } from 'next/navigation'
import React from 'react'

const SUBSCRIPTION_RESULT = ['success', 'fail'] as const

const useSubscriptionResult = () => {
  const showResultRef = React.useRef(false)

  const searchParams = useSearchParams()

  const subscriptionResult = searchParams.get('subscription_result') as
    | 'success'
    | 'fail'
    | null

  const shouldShowSubscriptionResult =
    subscriptionResult &&
    SUBSCRIPTION_RESULT.includes(subscriptionResult) &&
    !showResultRef.current

  React.useEffect(() => {
    if (shouldShowSubscriptionResult) {
      const result =
        subscriptionResult === 'fail' ? 'error' : subscriptionResult

      toaster[result]?.({
        title: t(`errors.payment.${result}.title`),
        message: t(`errors.payment.${result}.description`),
      })

      showResultRef.current = true
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldShowSubscriptionResult])
}

export default useSubscriptionResult
