import { useMount } from 'react-use'
import useRender from './use-render'

const useRenderOnMount = () => {
  const render = useRender()

  useMount(() => {
    render()
  })
}

export default useRenderOnMount
