import { type AttributeState, useForm } from '@/lib/hooks'
import { useTranslation } from 'react-i18next'

type SignUpForm = {
  email: AttributeState<string>
  password: AttributeState<string>
  confirmPass: AttributeState<string>
  isAcceptTerms: AttributeState<boolean>
}

export const useSignUpForm = () => {
  const { t } = useTranslation()

  return useForm<SignUpForm>(
    {
      email: {
        inputState: {
          value: '',
        },
        validate: (value) => {
          const isEmail = /\S+@\S+\.\S+/.test(value)

          if (!isEmail || !value.trim().length) {
            return t('EmailError')
          }

          return null
        },
      },
      password: {
        inputState: {
          value: '',
        },
        validate: (value) => (value.trim() !== '' ? null : t('EmailPassword')),
      },
      confirmPass: {
        inputState: {
          value: '',
        },
      },
      isAcceptTerms: {
        inputState: {
          value: false,
        },
        validate: (value) => (value ? null : 'error'),
      },
    },
    (formToValidate) => {
      const isEqualPasswords =
        formToValidate.confirmPass.inputState.value ===
        formToValidate.password.inputState.value

      if (isEqualPasswords) {
        return undefined
      }

      return { error: t('ConfirmError'), attribute: 'confirmPass' }
    },
  )
}
