import React from 'react'

export type AttributeInputState<Value = string> = {
  value: Value
  error?: string
  disabled?: boolean
  onChange?: React.ChangeEventHandler<HTMLInputElement>
}

export type AttributeState<Value = string> = {
  inputState: AttributeInputState<Value>
  validate?: (value: Value) => string | null
}

/**
 * Hook for handling form behaviour.
 * It's strict typed, so you may rely on value attribute types.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useForm = <Form extends Record<string, AttributeState<any>>>(
  initialForm: Form,
  onValidate?: (form: Form) =>
    | {
        error: string
        attribute: keyof Form
      }
    | undefined,
) => {
  const [form, setForm] = React.useState<Form>(initialForm)

  const handleChange = React.useCallback(
    <Attribute extends keyof Form>(
      attribute: Attribute,
    ): React.ChangeEventHandler<HTMLInputElement> =>
      (event) => {
        setForm((prev) => {
          return {
            ...prev,
            [attribute]: {
              ...prev[attribute],
              inputState: {
                ...prev[attribute].inputState,
                error: undefined,
                value:
                  typeof prev[attribute].inputState.value === 'boolean'
                    ? event.target.checked
                    : event.target.value,
              },
            },
          }
        })
      },
    [],
  )

  const handleValidate = React.useCallback(() => {
    const formValidateError = onValidate?.(form)

    const validatedForm = Object.entries(form).reduce(
      (acc, [key, keyValue]) => ({
        ...acc,
        [key]: {
          ...keyValue,
          inputState: {
            ...keyValue.inputState,
            error: (() => {
              if (formValidateError?.attribute === key) {
                return formValidateError.error
              }

              return keyValue.validate?.(keyValue.inputState.value) ?? undefined
            })(),
          },
        },
      }),
      {} as Form,
    )

    setForm(validatedForm)

    const values = Object.values(validatedForm)

    return (
      !formValidateError &&
      !!values &&
      values.every(({ inputState }) => !inputState.error)
    )
  }, [form, onValidate])

  const formWithOnChange = React.useMemo(
    () =>
      Object.entries(form).reduce(
        (acc, [key, keyValue]) => ({
          ...acc,
          [key]: {
            ...keyValue,
            inputState: {
              ...keyValue.inputState,
              onChange: handleChange(key),
            },
          },
        }),
        {} as {
          [Property in keyof Form]: Omit<
            AttributeState<Form[Property]['inputState']['value']>,
            'onChange'
          > & {
            onChange: (value: Form[Property]['inputState']['value']) => void
          }
        },
      ),
    [form, handleChange],
  )

  const reset = React.useCallback(() => {
    setForm((prev) =>
      Object.entries(prev).reduce(
        (acc, [key, keyValue]) => ({
          ...acc,
          [key]: {
            ...keyValue,
            inputState: {
              ...initialForm[key].inputState,
              onChange: keyValue.inputState.onChange,
            },
          },
        }),
        {} as Form,
      ),
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return { form: formWithOnChange, validate: handleValidate, reset }
}

export default useForm
