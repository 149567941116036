import { type AttributeState, useForm } from '@/lib/hooks'
import { useTranslation } from 'react-i18next'

type ForgotForm = {
  email: AttributeState<string>
}

export const useForgotForm = () => {
  const { t } = useTranslation()

  return useForm<ForgotForm>({
    email: {
      inputState: {
        value: '',
      },
      validate: (value) => {
        if (!value.trim().length) {
          return t('login.error.emptyForm')
        }

        return null
      },
    },
  })
}
